export const usePointer = () => {
  const fetchPointer = async () => {
    const device = useDevice().isDesktop ? 'desktop' : 'mobile';
    const deviceCapitalized = device.charAt(0).toUpperCase() + device.slice(1);

    let params = {
      channel: 'Website',
      subchannel: deviceCapitalized,
    };

    const { baseURL } = useRuntimeConfig().public;
    if (!baseURL) throw new Error('Base URL not found in runtime config');
    const pointerEndpoint = `/v2/menu/${device}`;

    return useFetch(pointerEndpoint, { params, baseURL, lazy: true, key: 'pointer', deep: true });
  };

  return {
    fetchPointer,
  };
};